import type { StdObject } from '~/types/internal'
import type { Tag } from '~/types/tag'

export const tagQuery = (
  id: string | number,
  query?: object,
  thumbs?: StdObject,
  hasPreview?: { expires: string; signature: string },
  placeholderData?: { data: Tag }
) =>
  queryOptions({
    queryKey: ['tags', id],
    queryFn: async (): Promise<{
      data: Tag
    }> => {
      const res = await useCustomFetch<{ data: Tag }>(
        `/public/tags/${hasPreview ? 'p/' : ''}${id}`,
        { ...query, ...(hasPreview ?? {}) },
        thumbs,
        {
          key: 'tag-detail',
        }
      )
      return res?.data.value as { data: Tag }
    },
    placeholderData,
  })

export const tagsQuery = (query?: object, thumbs?: StdObject, placeholderData?: { data: Tag[] }) =>
  queryOptions({
    queryKey: ['tags', query],
    queryFn: async (): Promise<{
      data: Tag[]
    }> => {
      const res = await useCustomFetch<{ data: Tag[] }>('/public/tags', query, thumbs)
      return res?.data.value as { data: Tag[] }
    },
    placeholderData,
  })
